<template>
    <b-row>
        <b-col cols="12">
            <div class="d-flex align-items-center">
                <div class="ml-auto">
                    <div
                        class="d-sm-flex mb-4 justify-content-end align-items-center"
                    >
                        <b-dropdown
                            id="dropdown-divider"
                            text="Opciones"
                            size="md"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            :disabled="
                                !permisos.includes(
                                    'inventario-saldo-query-export'
                                ) &&
                                !permisos.includes(
                                    'inventario-saldo-query-export-and-email'
                                ) &&
                                !permisos.includes(
                                    'inventario-saldo-crud-export'
                                ) &&
                                !permisos.includes(
                                    'inventario-saldo-crud-import'
                                )
                            "
                        >
                            <template #button-content>
                                <div
                                    class="d-flex justify-content-center align-items-center"
                                >
                                    <feather
                                        type="more-horizontal"
                                        size="1.5rem"
                                        class="text-secondary"
                                    ></feather>
                                </div>
                            </template>
                            <b-dropdown-item-button
                                v-for="(format, index) in formats"
                                :key="index"
                                @click="verifyFields(format.ext)"
                                :disabled="
                                    !permisos.includes(
                                        'inventario-saldo-query-export'
                                    )
                                "
                            >
                                {{ format.name }}
                            </b-dropdown-item-button>
                            <b-dropdown-item-button
                                @click="$bvModal.show('email-modal')"
                                :disabled="
                                    !permisos.includes(
                                        'inventario-saldo-query-export-and-email'
                                    )
                                "
                                >Enviar informe por
                                Correo</b-dropdown-item-button
                            >
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </b-col>
        <b-col cols="12" lg="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100 h-100">
                <div class="mt-5">
                    <b-form v-hotkey="keymap">
                        <b-row class="mt-5">
                            <!-- <b-col cols="12" sm="4">
                                <b-form-group>
                                    <label for="ano">Año</label>
                                    <yearPickerVue
                                        id="ano"
                                        v-model="form.ano"
                                    />
                                </b-form-group>
                            </b-col> -->

                            <b-col cols="12" sm="6">
                                <label for="bodega_id">Bodega</label>
                                <jautocomplete
                                    id="bodega_id"
                                    :items="bodegas"
                                    item-value="id"
                                    item-text="descripcion"
                                    open-on-focus
                                    v-model="$v.form.bodega_id.$model"
                                >
                                    <template v-slot:option="{ item }">
                                        <span
                                            >{{ item.codigo }} -
                                            {{ item.descripcion }}</span
                                        >
                                    </template>
                                </jautocomplete>
                            </b-col>

                            <b-col cols="12" sm="6">
                                <label for="lote_inventario_id"
                                    >Lote inventario</label
                                >
                                <jautocomplete
                                    id="lote_inventario_id"
                                    :items="lotes_inventario"
                                    item-value="id"
                                    item-text="nombre"
                                    open-on-focus
                                    v-model="$v.form.lote_inventario_id.$model"
                                >
                                    <template v-slot:option="{ item }">
                                        <span
                                            >{{ item.codigo }} -
                                            {{ item.nombre }}</span
                                        >
                                    </template>
                                </jautocomplete>
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col cols="12">
                                <b-form-group>
                                    <label for="filter-input"
                                        >Texto adicional para buscar</label
                                    >
                                    <b-form-input
                                        id="filter-input"
                                        type="text"
                                        placeholder="Escriba algún texto para buscar"
                                        v-model="search"
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div class="float-right mb-5">
                            <b-button
                                variant="primary"
                                size="md"
                                class="mt-4 d-flex align-items-center"
                                v-b-popover.hover.top="
                                    'Presione ctrl+b o cmd+b'
                                "
                                @click="getSaldosInventario()"
                            >
                                <feather
                                    type="search"
                                    size="1rem"
                                    class="text-blue-active mr-2"
                                ></feather>
                                <span>Consultar</span>
                            </b-button>
                        </div>
                    </b-form>
                </div>
                <div class="table-responsive mt-3">
                    <b-table
                        :fields="fields"
                        :items="saldos_inventario"
                        :tbody-tr-class="rowClass"
                        borderless
                        responsive
                        head-variant="light"
                        class="mb-0 hover-table text-nowrap"
                    >
                        <template #cell(referencia)="data">
                            <span
                                :class="`badge bg-light-success ${
                                    Theme === 'dark' && 'text-gray'
                                }`"
                            >
                                <feather
                                    type="hash"
                                    size="0.8rem"
                                    class="mr-1"
                                />
                                {{ data.item.item.referencia }}
                            </span>
                        </template>
                        <template #cell(descripcion)="data">
                            <span>
                                {{ data.item.item.descripcion }}
                            </span>
                        </template>
                        <template #cell(bodega)="data">
                            <span>
                                {{ data.item.bodega.codigo }} -
                                {{ data.item.bodega.descripcion }}
                            </span>
                        </template>
                        <template #cell(lote_inventario)="data">
                            <span>
                                {{ data.item.lote_inventario.codigo }} -
                                {{ data.item.lote_inventario.nombre }}
                            </span>
                        </template>
                        <template #cell(saldo)="data">
                            <span>
                                {{ data.item.saldo }}
                            </span>
                        </template>
                        <template #cell(precio_base_venta)="data">
                            <span>
                                {{
                                    currencyFormat(
                                        data.item.item.precio_base_venta
                                    )
                                }}
                            </span>
                        </template>
                        <template #cell(cliente)="data">
                            <span>
                                {{ data.value.nombre_comercial }}
                            </span>
                        </template>
                        <template #cell(cliente_id)="data">
                            <span>
                                {{ data.value }}
                            </span>
                        </template>
                        <template #cell(acciones)="data">
                            <feather
                                type="eye"
                                role="button"
                                @click="
                                    $router.push({
                                        name: 'Ver Factura de venta',
                                        params: {
                                            factura_id: data.item.id
                                        }
                                    })
                                "
                                v-b-popover.hover.top="'Ver'"
                            ></feather>
                            <feather
                                v-if="
                                    permisos.includes(
                                        'inventario-saldo-export-individual-and-email'
                                    )
                                "
                                type="mail"
                                role="button"
                                class="ml-2"
                                v-b-popover.hover.top="'Enviar por correo'"
                                @click="sendSingleFactura(data.item.id)"
                            ></feather>
                            <feather
                                v-else
                                type="mail"
                                role="button"
                                class="ml-2 text-secondary"
                                v-b-popover.hover.top="'Enviar por correo'"
                            ></feather>
                            <feather
                                v-if="
                                    permisos.includes(
                                        'inventario-saldo-export-individual'
                                    )
                                "
                                type="printer"
                                role="button"
                                v-b-popover.hover.top="'Imprimir'"
                                class="ml-2"
                                @click="printFactura(data.item.id)"
                            ></feather>
                            <feather
                                v-else
                                type="printer"
                                role="button"
                                class="text-secondary ml-2"
                                v-b-popover.hover.top="'Imprimir'"
                            ></feather>
                            <feather
                                type="repeat"
                                role="button"
                                class="ml-2"
                                @click="
                                    $router.push({
                                        name: 'Crear devolucion de factura',
                                        params: {
                                            inventario_saldo_id: data.item.id
                                        }
                                    })
                                "
                                v-b-popover.hover.top="'Devolución de factura'"
                            ></feather>
                        </template>
                    </b-table>
                </div>
                <div class="p-3 d-flex align-items-center">
                    <b-form-group
                        label="Número de registros"
                        label-for="per-page-select"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            id="per-page-select"
                            v-model="pagination.perPage"
                            :options="pagination.pageOptions"
                            @change="getSaldosInventario(1, $event)"
                            size="sm"
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            first-number
                            last-number
                            size="sm"
                            v-model="pagination.currentPage"
                            :total-rows="pagination.totalRows"
                            :per-page="pagination.perPage"
                            @input="
                                getSaldosInventario($event, pagination.perPage)
                            "
                            align="fill"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
            </b-card>
            <selectFieldsReport
                :modulo="moduleName"
                :format="selectedFormat"
                :fields="
                    fieldsAvailables[
                        selectedFormat ? selectedFormat.toLowerCase() : 'excel'
                    ]
                "
                :aditionalFields="{ term: search, año: form.ano, ...form }"
                @close="selectedFormat = null"
            />
            <emailModal
                :sender="configEmail"
                :modulo="firstToUpperString(moduleName)"
                :fieldsAvailables="fieldsAvailables"
                :extraParamsDatajson="{ ...form, año: form.ano }"
            />
            <emailModal
                :id="'email-modal-single'"
                :sender="configEmail"
                :modulo="firstToUpperString(moduleName)"
                :fieldsAvailables="fieldsAvailables"
                :url="'administracion/inventario/inventario-saldo/query'"
                :alternativeOpc="'FIND_BY_ID'"
                :extraParamsDatajson="singleEmailParams"
            />
        </b-col>
        <b-col cols="12">
            <div
                class="d-sm-flex mt-4 justify-content-between align-items-center"
            >
                <!-- <b-button
                    variant="primary"
                    size="md"
                    class="d-flex align-items-center mt-4 mt-sm-0 mr-4"
                    @click="addRegister"
                    v-b-popover.hover.top="'Presione alt+a o option+a'"
                    :disabled="!permisos.includes('inventario-saldo-crear')"
                >
                    <feather
                        type="plus"
                        size="1rem"
                        class="mr-2 blue-active-text"
                    ></feather>
                    Nueva Factura
                </b-button> -->
                <b-dropdown
                    id="dropdown-divider"
                    text="Opciones"
                    size="md"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    :disabled="
                        !permisos.includes('inventario-saldo-query-export') &&
                        !permisos.includes(
                            'inventario-saldo-query-export-and-email'
                        ) &&
                        !permisos.includes('inventario-saldo-crud-export') &&
                        !permisos.includes('inventario-saldo-crud-import')
                    "
                >
                    <template #button-content>
                        <div
                            class="d-flex justify-content-center align-items-center"
                        >
                            <feather
                                type="more-horizontal"
                                size="1.5rem"
                                class="text-secondary"
                            ></feather>
                        </div>
                    </template>
                    <b-dropdown-item-button
                        v-for="(format, index) in formats"
                        :key="index"
                        @click="verifyFields(format.ext)"
                        :disabled="
                            !permisos.includes('inventario-saldo-query-export')
                        "
                    >
                        {{ format.name }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                        @click="$bvModal.show('email-modal')"
                        :disabled="
                            !permisos.includes(
                                'inventario-saldo-query-export-and-email'
                            )
                        "
                        >Enviar informe por Correo</b-dropdown-item-button
                    >
                </b-dropdown>
            </div>
        </b-col>
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { mapGetters, mapState, mapActions } from 'vuex'
import selectFieldsReport from '@/components/shared/select-fields-report/select-fields-report'
import emailModal from '@/components/shared/email/email'
import { addListenerCommands, removeListenerCommands } from '@/utils/others'
import {
    firstToUpperString,
    currencyFormat,
    getComposedSearch
} from '@/utils/others'
import { validationMixin } from 'vuelidate'
// import { required } from 'vuelidate/lib/validators'
// import { compareDates, dateNow } from '@/utils/date'
// import * as XLSX from 'xlsx'
import print from 'print-js'
import Jautocomplete from '../../../../components/shared/autocomplete/jautocomplete.vue'
// import yearPickerVue from '../../../../components/shared/year-picker/year-picker.vue'

const formDefault = () =>
    JSON.parse(
        JSON.stringify({
            ano: new Date().getFullYear().toString(),
            lote_inventario_id: null,
            bodega_id: null
        })
    )

const { API } = services

export default {
    name: 'MainFacturasVenta',
    components: {
        selectFieldsReport,
        emailModal,
        Jautocomplete
    },
    mixins: [validationMixin],
    mounted() {
        addListenerCommands(this)
        this.getEmpresaEmails()
        this.getAvailableFields()
        this.getConsecutivos()
        this.getSaldosInventario()
        this.getBodegas()
        this.getLotesInventarios()
        console.log(this.getUltimaSede)
        this.form.bodega_id = this.getUltimaSede.bodega
            ? this.getUltimaSede.bodega.id
            : null
        // console.log(this)
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    data: () => ({
        fields: [
            { key: 'referencia', label: 'Referencia' },
            { key: 'descripcion', label: 'Nombre producto' },
            { key: 'bodega', label: 'Bodega' },
            { key: 'lote_inventario', label: 'Lote' },
            { key: 'saldo', label: 'Saldo de inventario' },
            { key: 'precio_base_venta', label: 'Precio Venta' }
            // { key: 'acciones', label: 'Acciones' }
        ],
        lotes_inventario: [],
        bodegas: [],
        form: formDefault(),
        saldos_inventario: [],
        consecutivos: [],
        pagination: {
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 20, 30, 40, 50, 100]
        },
        singleEmailParams: {},
        search: '',
        fieldsAvailables: {},
        configEmail: null,
        selectedFormat: null,
        moduleName: 'inventario-saldo',
        formats: [
            { id: 1, name: 'Generar Informe Excel', ext: 'EXCEL' },
            { id: 2, name: 'Generar Informe Pdf', ext: 'PDF' }
        ]
    }),
    validations: {
        form: {
            // ano: {},
            lote_inventario_id: {},
            bodega_id: {}
        }
    },
    methods: {
        currencyFormat,
        showLog(data) {
            console.log(data)
        },
        firstToUpperString,
        printFactura(saldo_inventario_id) {
            API.POST({
                url: 'administracion/inventario-saldo/imprimir',
                data: {
                    p_datajson: {
                        id: saldo_inventario_id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        const base64 = dato.replace(
                            'data:application/pdf;base64,',
                            ''
                        )
                        print({
                            printable: base64,
                            type: 'pdf',
                            base64: true
                        })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        sendSingleFactura(factura_id) {
            this.singleEmailParams = { id: factura_id }
            this.$bvModal.show('email-modal-single')
        },
        searchData() {
            this.getSaldosInventario()
        },
        getSaldosInventario(page = 1, limit = this.pagination.perPage) {
            const { bodega_id, lote_inventario_id } = this.form

            const term = getComposedSearch(this.search)

            API.POST({
                url: 'administracion/inventario/inventario-saldo/query',
                data: {
                    p_datajson: {
                        page,
                        term,
                        limit,
                        bodega_id,
                        lote_inventario_id,
                        // año: ano,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FILTER',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato: { content, totalContent, size, number },
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.saldos_inventario = content
                        this.pagination = {
                            ...this.pagination,
                            totalRows: totalContent,
                            perPage: size,
                            currentPage: number
                        }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getLotesInventarios() {
            API.POST({
                url: 'administracion/inventario/lote-inventario/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.lotes_inventario = [...dato]
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getBodegas() {
            API.POST({
                url: 'administracion/inventario/bodega/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.bodegas = [...dato]
                        // console.log(this.bodegas)
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        verifyFields(ext) {
            this.selectedFormat = ext
            this.$bvModal.show('modal-fields-report')
        },
        getAvailableFields() {
            if (this.permisos.includes('inventario-saldo-query-export')) {
                API.POST({
                    url: 'report/available-fields',
                    data: {
                        p_datajson: {
                            modulo: this.moduleName
                        },
                        p_opc: 'GET_FIELD',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            // console.log(dato)
                            this.fieldsAvailables = dato
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        getConsecutivos() {
            if (this.getUltimaEmpresa) {
                API.POST({
                    url: 'administracion/consecutivo/query',
                    data: {
                        p_datajson: {
                            clase_documento_id: 2,
                            empresa_id: this.getUltimaEmpresa.id
                        },
                        p_opc: 'GET_ALL_BY_CLASE_DOCUMENTO',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            // console.log(dato)
                            this.consecutivos = dato.map((c) => {
                                return c.prefijo === ''
                                    ? {
                                          ...c,
                                          prefijo: 'Sin prefijo'
                                      }
                                    : c
                            })
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        getEmpresaEmails() {
            API.POST({
                url: 'cartero/config-mail/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FIND_BY_EMPRESA',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.configEmail = dato[0]
                    } else if (dato.length === 0 && status !== 'err') {
                        this.$bvModal
                            .msgBoxConfirm(
                                'Esta empresa no posee configuración de correo para reportes o envío de documentos por email, desea crear una configuración de correo para esta empresa?',
                                {
                                    title: 'Advertencia!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'warning',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'warning',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then((value) => {
                                if (value) {
                                    this.$router
                                        .push({
                                            path: '/home/general/generales/configuracion-email/crear-configuracion-email'
                                        })
                                        .catch((error) => error)
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'active') return 'active'
        },
        addRegister() {
            this.$router.push(
                '/home/administrativo/documentos/facturas-venta/crear-factura-venta'
            )
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.form[key]
            return $dirty ? !$error : null
        },
        ...mapActions(['getImportFormat'])
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'alt+a': this.addRegister,
                    'command+b': this.getSaldosInventario
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'alt+a': this.addRegister,
                    'ctrl+b': this.getSaldosInventario
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 17 }
            return json
        },
        permisos() {
            return this.getPermisos.permisos_authorities
        },
        ...mapGetters('usuario', [
            'getUserData',
            'getAuditoriaJson',
            'getPermisos'
        ]),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapGetters('sedes', ['getUltimaSede']),
        ...mapState(['Theme'])
    }
}
</script>

<style></style>
